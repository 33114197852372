import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"

export default function StaffTemplate({data}) {
  if (!data) { return null };
  const { mdx } = data
  const { frontmatter, html } = mdx
  const post = data.mdx	
  return (
    <Layout breadcrumbs={["Staff"]}>
	<div className="page__header page__header--blank">
      <div className="page__header-content "></div>
    </div>
	<div className="container">
		<div id="primary" className="primary--default">
		  <article id="post-23">
		    <div className="row">
			  <div className="col-12"> 
			   <MDXRenderer>{post.body}</MDXRenderer>
		      </div>
		    </div>
		  </article>
		</div>
	</div>
    </Layout>
  )
}
export const staffQuery = graphql`
 query StaffPage($path: String) {
  mdx(frontmatter: {path: {eq: $path}}) {
    body
    frontmatter {
      tableClass		
      title
	  staff_title
	  staff_co_logo
	  staff_content
    }
  }
}
`